<template>
  <div class="setting">
    <Panel title="地图设置" @close="handleClose">
      <p class="setting-title">地图设置</p>
      <div class="setting-item" v-for="item in setting" :key="item.name">
        <div class="setting-item-header">
          <p class="setting-item-title">{{ item.name }}</p>
          <button>
            <i
              :class="[
                'icon',
                item.checkAll ? 'icon-visible' : 'icon-invisible'
              ]"
              @click="checkAll(item)"
            ></i>
          </button>
        </div>
        <div class="setting-item-content">
          <el-checkbox
            class="checkbox"
            v-for="childItem in item.children"
            :key="childItem.value"
            v-model="childItem.checked"
            @change="handleChange(childItem, item)"
            >{{ childItem.label }}</el-checkbox
          >
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Panel from 'c/basic/Panel';
export default {
  name: 'MapSetting',
  components: {
    Panel
  },
  computed: {
    setting() {
      return [
        {
          name: '状态栏',
          checkAll: true,
          children: [
            { label: '经度', value: 'Lng', checked: this.mapLngVisible },
            { label: '纬度', value: 'Lat', checked: this.mapLatVisible },
            {
              label: '旋转角度',
              value: 'Heading',
              checked: this.mapHeadingVisible
            },
            {
              label: '俯视角度',
              value: 'Pitch',
              checked: this.mapPitchVisible
            },
            {
              label: '相机高度',
              value: 'Height',
              checked: this.mapHeightVisible
            }
          ]
        },
        {
          name: '工具栏',
          checkAll: true,
          children: [
            { label: '绘制工具', value: 'Draw', checked: this.mapDrawVisible },
            {
              label: '测量工具',
              value: 'Measure',
              checked: this.mapMeasureVisible
            },
            {
              label: '比例尺',
              value: 'ScaleRuler',
              checked: this.mapScaleRulerVisible
            }
          ]
        },
        {
          name: '地图操作工具',
          checkAll: true,
          children: [
            // {
            //   label: '2D/3D切换',
            //   value: 'ModelSwitch',
            //   checked: this.mapModelSwitchVisible
            // },
            {
              label: '地图放大缩小',
              value: 'Zoom',
              checked: this.mapZoomVisible
            }
          ]
        },
        {
          name: '指北针',
          checkAll: true,
          children: [
            {
              label: '指北针',
              value: 'Compass',
              checked: this.mapCompassVisible
            }
          ]
        }
      ];
    },
    ...mapState('baseMap', [
      'mapLngVisible',
      'mapLatVisible',
      'mapHeadingVisible',
      'mapPitchVisible',
      'mapHeightVisible',
      'mapDrawVisible',
      'mapMeasureVisible',
      'mapScaleRulerVisible',
      'mapModelSwitchVisible',
      'mapZoomVisible',
      'mapCompassVisible'
    ])
  },
  methods: {
    updatePage(item) {
      const { value, checked } = item;
      this.$store.commit(`baseMap/setMap${value}Visible`, checked);
    },
    handleChange(childItem, data) {
      // 遍历所有子项，判断是否全部取消或选中
      let { checked } = childItem;
      const { children } = data;
      // 同步更到页面
      this.updatePage(childItem);
      let state = true;
      // 判断所有子项是否和当前项勾选状态一样，一样则同步修改父项勾选框状态
      for (let i = 0; i < children.length; i++) {
        const item = children[i];
        // 不一致时，跳出循环
        if (item.checked !== checked) {
          state = false;
          break;
        }
      }
      if (state) {
        data.checkAll = checked;
      }
    },
    checkAll(data) {
      data.checkAll = !data.checkAll;
      // 修改子项勾选状态
      data.children.forEach(item => {
        item.checked = data.checkAll;
      });
    },
    handleClose() {
      this.$router.push('/workbench/map');
    }
  }
};
</script>

<style lang="less" scoped>
.setting {
  /deep/ .panel-container {
    padding: 20px 10px;
  }
  &-title {
    margin-bottom: 10px;
    color: #333;
    font-size: 18px;
  }
  &-item {
    width: 310px;
    margin-bottom: 20px;
    background: #f4f7ff;
    border: 1px solid #d3e1ff;
    border-radius: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 38px;
      padding: 0 10px;
      background-color: rgba(80, 124, 255, 0.8);
      button {
        padding: 2px;
        &:active {
          box-shadow: 0 0 1xp 1px rgba(0, 0, 0, 1) inset;
        }
      }
    }
    &-title {
      color: #fff;
      font-size: 18px;
    }
    &-content {
      padding: 10px 10px 0;
      .checkbox {
        margin-bottom: 20px;
        /deep/ .el-checkbox {
          &__input {
            position: relative;
            top: -2px;
            font-size: 0;
            &.is-checked {
              .el-checkbox__inner {
                background-color: #1ad546 !important;
                border-color: #1ad546 !important;
                z-index: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
